import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import './builderCASelect.scss';

export const BuilderCASelect = (props) => {
  const { label, size, formData, setFormData } = props;

  const [selectedType, setSelectedType] = useState('percent');
  const [selectedValue, setSelectedValue] = useState('comp_amount_pct');

  useEffect(() => {
    if (formData.comp_amount_pct) {
      setSelectedValue('comp_amount_pct');
      setSelectedType('percent');
    }
    if (formData.comp_pepm_dollar) {
      setSelectedValue('comp_pepm_dollar');
      setSelectedType('pepm');
    }
    if (formData.comp_pppm_dollar) {
      setSelectedValue('comp_pppm_dollar');
      setSelectedType('pppm');
    }
  }, [formData]);

  const onChangeSelect = useCallback(
    (event) => {
      const value = event.target.value;

      setSelectedType(event.target.value);

      if (value === 'percent') {
        setSelectedValue('comp_amount_pct');
        setFormData((prev) => ({
          ...prev,
          comp_amount_pct: '10',
          comp_pepm_dollar: null,
          comp_pppm_dollar: null,
        }));
      }

      if (value === 'pepm') {
        setSelectedValue('comp_pepm_dollar');
        setFormData((prev) => ({
          ...prev,
          comp_amount_pct: null,
          comp_pepm_dollar: '',
          comp_pppm_dollar: null,
        }));
      }

      if (value === 'pppm') {
        setSelectedValue('comp_pppm_dollar');
        setFormData((prev) => ({
          ...prev,
          comp_amount_pct: null,
          comp_pepm_dollar: null,
          comp_pppm_dollar: '',
        }));
      }
    },
    [setFormData],
  );

  const onChangeInput = useCallback(
    (event, key) => {
      setFormData((prev) => ({
        ...prev,
        [key]: event.target.value,
      }));
    },
    [setFormData],
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ marginRight: 1 }}>
        <Select
          size={size}
          onChange={onChangeSelect}
          value={selectedType}
          style={{ textAlign: 'left' }}
          sx={{ width: 110 }}
          onClose={() => {
            setTimeout(() => {
              document.activeElement.blur();
            }, 0);
          }}
          // disabled={disabled}
          required
        >
          <MenuItem value="percent">Percent</MenuItem>
          <MenuItem value="pepm">PEPM</MenuItem>
          <MenuItem value="pppm">PPPM</MenuItem>
        </Select>
      </FormControl>
      <TextField
        // style={{ width: '100%' }}
        label={label}
        onChange={(event) => onChangeInput(event, selectedValue)}
        value={formData[selectedValue]}
        type="number"
        size="small"
        sx={{ flex: 1 }}
        required
        InputProps={{
          inputProps: { min: 0, max: selectedType === 'percent' ? 100 : 999999 },
          startAdornment:
            selectedType !== 'percent' ? (
              <InputAdornment position="start">$</InputAdornment>
            ) : (
              <></>
            ),
          endAdornment:
            selectedType === 'percent' ? <InputAdornment position="end">%</InputAdornment> : <></>,
        }}
      />
    </Box>
  );
};

BuilderCASelect.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  formData: PropTypes.object,
  setFormData: PropTypes.func,
};
